<template>
  <v-tooltip
    :bottom="bottomTooltip"
    :right="rightTooltip"
    :top="topTooltip"
    :disabled="tooltip === ''"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-layout shrink v-on="on" v-bind="attrs">
        <DefaultButton
          :icon="!label"
          :bgColor="color"
          :class="['btn-style ma-0', active && 'active', label && 'px-1']"
          @click.prevent="$emit('clicked')"
        >
          <v-img :src="icon" :class="[label ? 'label-img-style' : 'img-style', label && 'mr-1']"> </v-img>
          <DefaultLabel v-if="label">{{ label }}</DefaultLabel>
        </DefaultButton>
      </v-layout>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
    },
    icon: {
      default: require("@/assets/editor/move.svg"),
    },
    color: {
      type: String,
      default: "white",
    },
    bottomTooltip: {
      type: Boolean,
      default: false,
    },
    rightTooltip: {
      type: Boolean,
      default: false,
    },
    topTooltip: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: ""
    }
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
};
</script>

<style scoped lang="scss">
.btn-style {
  border: 1px solid var(--v-border-base) !important;
  border-radius: 5px !important;
  &.v-btn--icon::before {
    border-radius: 5px !important;
  }
  &.v-ripple__container {
    border-radius: 0px !important;
  }
  &.active {
    border: 1px solid var(--v-primary-base) !important;
  }
}
.img-style {
  min-height: 30px;
  max-height: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  width: 30px;
}
.label-img-style {
  min-height: 18px;
  max-height: 18px;
  height: 18px;
  min-width: 18px;
  max-width: 18px;
  width: 18px;
}
</style>